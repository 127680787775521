import React from "react";
import "./TopButton.css";

export default function TopButton({ theme }) {
  function GoUpEvent() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  function scrollFunction() {
    const topButton = document.getElementById("topButton");
    if (topButton) {
      if (
        document.body.scrollTop > 30 ||
        document.documentElement.scrollTop > 30
      ) {
        topButton.style.visibility = "visible";
      } else {
        topButton.style.visibility = "hidden";
      }
    }
  }

  window.onscroll = function () {
    scrollFunction();
  };

  const onMouseEnter = (color) => {
    /* For arrow icon */
    const arrow = document.getElementById("arrow");
    arrow.style.color = color;
  };

  const onMouseLeave = (color) => {
    /* For arrow icon */
    const arrow = document.getElementById("arrow");
    arrow.style.color = color;
  };

  return (
    <div
      onClick={GoUpEvent}
      id="topButton"
      style={{
        color: theme.body,
        border: `solid 1px ${theme.text}`,
      }}
      title="Go up"
      onMouseEnter={() => onMouseEnter("darkblue")}
      onMouseLeave={() => onMouseLeave("darkblue")}
    >
      <i
        className="fas fa-arrow-up"
        id="arrow"
        aria-hidden="true"
        style={{ color: "#65120b" }}
      />
    </div>
  );
}
