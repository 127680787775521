const seo = {
  title: "Shridantay Global Trade",
  description:
    "Your go-to destination for premium spices. We're dedicated to delivering exceptional quality and flavor. Elevate your culinary experience with KSJ!",
};

//Home Page
const greeting = {
  title: "SHRIDANTAY GLOBAL TRADE",
  logo_name: "kasjOverseas",
  subTitle:
    "We offer top-notch exports like Dollar Chana, Garlic, Ginger, and more, while also importing vital commodities such as Aluminum scrap, Copper wire scrap, and Brass scrap. With us, expect reliability and excellence in every transaction",
};

const socialMediaLinks = [
  {
    name: "Gmail",
    link: "mailto:info@shridantay.com",
    fontAwesomeIcon: "fa-google",
    backgroundColor: "#D14836",
  },
  {
    name: "facebook",
    link: "https://www.facebook.com/profile.php?id=61556391751387",
    fontAwesomeIcon: "fa-facebook",
    backgroundColor: "#00008B",
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/shridantay?igsh=MWt2dnRyaTFuMncxNw==",
    fontAwesomeIcon: "fa-instagram",
    backgroundColor: "#E4405F",
  },
  {
    name: "Whatsapp",
    link: "https://api.whatsapp.com/send?phone=7974194260&text=Hello",
    fontAwesomeIcon: "fa-whatsapp",
    backgroundColor: "#008000",
  },

  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/company/dryzilla-technology/",
    fontAwesomeIcon: "fa-linkedin",
    backgroundColor: "#00008B",
  },
];

// Projects Page
const projectsHeader = {
  title: "About Us",
};

const contactPageData = {
  contactSection: {
    title: "Contact Us",
    //   description:
    // "Ksg: Your one-stop digital partner. We excel in website development, e-commerce solutions, SEO, digital marketing, graphic design, and content writing. Elevate your brand's online presence with our comprehensive expertise.Digitalease is your go-to destination for cutting-edge development projects and tech solutions that elevate your business. It's a platform born out of a deep-rooted understanding of the intricacies of technology and a dedication to delivering top-notch solutions for your digital needs.",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    locality: "Nimach",
    country: "IN",
    region: "Madhya Pradesh",
    postalCode: "458441",
    avatar_image_path: "address_image.svg",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export { seo, greeting, socialMediaLinks, projectsHeader, contactPageData };
