import React from "react";
import "../../containers/products/Products.css";
import { Fade } from "react-reveal";

export default function Products(props) {
  const theme = props.theme;
  return (
    <div className="main" id="about">
      <div className="skills-header-div">
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: "#ab5f59" }}>
            <span
              style={{
                // borderBottom:"4px solid #03033d" ,
                fontFamily: "Times New Roman",
              }}
            >
              ABOUT US
            </span>
          </h1>
        </Fade>

        <div className="contact-heading-text-div">
          <Fade right duration={2000} distance="20px">
            <div className="experience-heading-img-div">
              <img
                alt="Logo"
                src={require("../../assests/images/logo.jpeg")}
              ></img>
            </div>
            <p
              className="greeting-text-p subTitle"
              style={{
                color: "rgb(52 29 64)",
                fontSize: "14px",
                lineHeight: "1.6",
                //  textAlign: "centre",
                //  fontFamily: "Arial, sans-serif",
              }}
            >
              We are Shridantay Global Trade Private Limited, established in the
              year 2024. Based in Indore, we specialize in the import and export
              of various commodities. Our primary focus lies in exporting grains
              such as chickpeas, garlic, ginger, onions, red chili, potatoes,
              and spices. Additionally, we operate a dedicated import division
              dealing with scrap materials including aluminum, copper, brass,
              steel, and iron. Rest assured, we are duly registered with the
              Indian Ministry of Corporate Affairs and hold all necessary
              certifications and authorizations to conduct our operations. This
              commitment to compliance underscores our dedication to fostering
              trust and reliability, crucial aspects of global business
              dealings.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  );
}
