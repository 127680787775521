import React, { Component } from "react";
import "./projectCard.css";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
class ProjectCard extends Component {
  render() {
    const products = this.props.products;
    const theme = this.props.theme;

    return (
      <Fade bottom duration={2000} distance="20px">
        {/* <Link to="/contact" style={{ textDecoration: "none", color: "inherit" }}>         */}
        <div className="cert-card">
          <Link
            to={`/products/${products.detail}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="content">
              <img
                className="logo_img"
                src={require(`../../assests/images/${products.logo_path}`)}
                alt={products.alt_name}
              />
            </div>
            <div className="cert-body">
              <h2
                className="cert-body-title"
                style={{ color: theme.text, fontFamily: "Times New Roman" }}
              >
                {products.title}
              </h2>
              <h4
                className="cert-body-subtitle"
                style={{ color: "black", textDecoration: "underline" }}
              >
                {products.subtitle}
              </h4>
            </div>
          </Link>
        </div>
      </Fade>
    );
  }
}

export default ProjectCard;
