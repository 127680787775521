import React, { Component } from "react";
import "./Header.css";
import { Fade } from "react-reveal";
import SeoHeader from "../seoHeader/SeoHeader";
import { NavLink, Link } from "react-router-dom";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomed: false,
    };
  }

  toggleZoom = () => {
    this.setState({ zoomed: !this.state.zoomed });
  };

  render() {
    const { zoomed } = this.state;
    const theme = this.props.theme;

    return (
      <Fade top duration={1000} distance="20px">
        <div>
          <SeoHeader />
          <header className="header">
            <NavLink to="/home" tag={Link} className="logo">
              <img
                style={{ width: "110px", height: "110px" }}
                alt="Logo"
                src={require("../../assests/images/logo.jpeg")}
              ></img>
            </NavLink>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn">
              <span className="navicon"></span>
            </label>
            <ul className="menu" style={{ backgroundColor: theme.body }}>
              <li>
                <NavLink
                  to="/home"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                >
                  Back To Home
                </NavLink>
              </li>
            </ul>
          </header>
        </div>
      </Fade>
    );
  }
}

export default Header;
