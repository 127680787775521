import React, { Component } from "react";
import "../../containers/projects/projectCard.css";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

class ProjectCard extends Component {
  render() {
    const products = this.props.products;
    const theme = this.props.theme;

    const isDesktop = window.innerWidth > 768; // Assuming 768px is your breakpoint for desktop

    return (
      <>
        <div className="main" id="certificates">
          <h1 className="skills-header" style={{ color: "#ab5f59" }}>
            <span
              style={{
                borderBottom: "4px solid black",
                fontFamily: "Times New Roman",
              }}
            >
              Certificates
            </span>
          </h1>
        </div>

        <div className="certs-body-div">
          <Fade bottom duration={2000} distance="20px">
            <div className="cert-card">
              {isDesktop ? (
                <a
                  href="https://drive.google.com/file/d/14EcTAVtEcZvXRte0ulI8_0-ps1gYnOOF/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="logo_img"
                    src={require(`../../assests/images/certificate.jpg`)}
                    alt="img "
                  />
                </a>
              ) : (
                <div className="content">
                  <img
                    className="logo_img"
                    src={require(`../../assests/images/certificate.jpg`)}
                    alt="img "
                  />
                </div>
              )}

              <div className="cert-body">
                <h2
                  className="cert-body-title"
                  style={{
                    color: theme.text,
                    fontFamily: "Times New Roman",
                  }}
                >
                  Certificate
                </h2>
                <h4
                  className="cert-body-subtitle"
                  style={{
                    color: "black",
                    textDecoration: "underline",
                  }}
                >
                  IEC-ABMCS5593E
                </h4>
              </div>
            </div>
          </Fade>
        </div>
      </>
    );
  }
}

export default ProjectCard;
