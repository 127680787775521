import React from "react";
import "./Products.css";
import ProjectCard from "../projects/ProjectCard";
import TypingAnimation from "./TypingAnimation";
import { products } from "../../products";
import { Fade } from "react-reveal";
const words = "Our Products".split(" ");

export default function Products(props) {
  const theme = props.theme;
  return (
    <div className="main" id="products">
      <div className="skills-header-div">
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: "#ab5f59" }}>
            {words.map((word, index) => (
              <span
                key={index}
                style={{
                  borderBottom: index === 1 ? "4px solid black" : "inherit",
                  fontFamily: "Times New Roman",
                }}
              >
                {word}{" "}
              </span>
            ))}
            <TypingAnimation style={{ fontSize: "1px" }} />
          </h1>
        </Fade>
        <div className="certs-body-div">
          {products.projects.map((prod) => {
            return <ProjectCard products={prod} theme={theme} key={prod} />;
          })}
        </div>
      </div>
    </div>
  );
}
