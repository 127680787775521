export const blueTheme = {
  body: "#EDF9FE",
  text: "#001C55",
  expTxtColor: "#000a12",
  highlight: "#A6E1FA",
  dark: "#00072D",
  secondaryText: "#7F8DAA",
  imageHighlight: "#0E6BA8",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#0A2472",
  headerColor: "#0E6BA877",
  splashBg: "#001C55",
};
export const blackTheme = {
  body: "#fff",
  text: "#14213d",
  expTxtColor: "#000a12",
  highlight: "#ffffff",
  dark: "#000000",
  secondaryText: "#5A6377",
  imageHighlight: "#fca311",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#8d99ae",
  headerColor: "#fca31177",
  splashBg: "#14213d",
};
export const violetTheme = {
  body: "#F4EEFC",
  text: "#430A58",
  expTxtColor: "#000a12",
  highlight: "#D6BEF4",
  dark: "#21052C",
  secondaryText: "#875599",
  imageHighlight: "#9b5de5",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#763D8B",
  headerColor: "#9b5de577",
  splashBg: "#430A58",
};
export const tealTheme = {
  body: "#03033d0d",
  text: "#390202",
  expTxtColor: "#000a12",
  highlight: "#03033d0d",
  dark: "#031E26",
  secondaryText: "#2E0303",
  imageHighlight: "#07beb8",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#56a3a6",
  headerColor: "#07beb877",
};

export const chosenTheme = blackTheme;
