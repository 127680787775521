import React, { Component } from "react";
import Header from "../../components/header/Headers.js";
import TopButton from "../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./productDetail.css";
import Footer from "../../components/footer/Footer.js";
import productsData from "../../productDetail.js";

class ProductPage extends Component {
  render() {
    const { theme, match } = this.props;
    const productName = match.params.productName; 
    const product = productsData.find(product => product.name.toLowerCase() === productName.toLowerCase());
    console.log(productName)
    console.log(`products-${product}`);
    if (!product) {
      return <div>Product not found.</div>;
    }

    return (
      <div>
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
              <div className="contact-heading-text-div">
                <h1 className="contact-heading-text" style={{ color: "#580404e0", fontFamily: "Times New Roman"}}>
                    <span style={{ borderBottom:  "4px solid #fca326"  }}>
                     {productName.toUpperCase()}
                    </span>
                </h1>
              </div>
          </Fade>
          <Fade right duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-img-div">
                <img alt="" src={require(`../../assests/images/${product.image}`)} />
              </div>
              <div className="contact-heading-text-div">
                {product.paragraphs.map((paragraph, index) => (
                  <p key={index} className="greeting-text-p subTitle" style={{ color: "rgb(0 0 0)", fontSize: "15px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Times New Roman" }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
          </Fade>
          <div></div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div"></div>
          </Fade>
        </div>
        <Footer theme={theme} />
        <TopButton theme={theme} />
      </div>
    );
  }
}

export default ProductPage;
