const productsData = [
  {
    id: 1,
    name: "Aluminium Scrap",
    image: "alu.png",
    paragraphs: [
      "Aluminium scrap refers to discarded or <strong>surplus aluminum materials</strong>, including used beverage cans, manufacturing offcuts, aluminum siding, and obsolete aluminum products.",
      "Aluminium scrap is <strong>Highly Recyclable</strong>, requiring only a fraction of the energy needed for primary aluminum production, thereby conserving natural resources and reducing energy consumption.",
      "Its <strong>Types</strong> includes post-consumer scrap (e.g., cans), industrial scrap (e.g., manufacturing waste), and obsolete scrap (e.g., old window frames), each with unique recycling pathways.",
      "<strong>Processing and Recycling involves</strong> collection, sorting, shredding, melting, purifcation, and casting to produce high-quality aluminum ingots or billets for manufacturing new products.",
      "Recycled aluminium derived from scrap is used in various industries, including <strong>construction transportation and packaging</strong> for making structural components, automotive parts.",
    ],
  },
  {
    id: 2,
    image: "d2.png",
    name: "Copper Wire",
    paragraphs: [
      " Copper pure wire scrap consists of clean, unalloyed copper wire that is free from any insulation, coatings, or other contaminants.",
      " Copper pure wire scrap is <strong>Highly Valuable</strong> for recycling due to its high copper content and purity, making it desirable for various industries.",
      " The <strong>Recycling Process</strong> for copper pure wire scrap typically involves shredding or granulating the wire to reduce its size and facilitate melting",
      " The shredded copper wire scrap is <strong>melted</strong> in a furnace, and impurities such as oxides and other non-metallic contaminants are removed through various purification methods.",
      "The high conductivity and corrosion resistance of copper make it indispensable in electrical and electronic applications.",
    ],
  },
  {
    id: 3,
    image: "d3.png",
    name: "Iron Scrap",
    paragraphs: [
      "Iron scrap is collected, sorted, and processed at recycling facilities. The process involves shredding or shearing the scrap into manageable pieces, followed by melting in furnaces to create new steel products.",
      "Recycling iron scrap reduces the need for extracting raw iron ore, conserving natural resources and reducing energy consumption and greenhouse gas emissions associated with iron and steel production.",
      "Iron scrap recycling contributes significantly to the economy by creating job opportunities in recycling facilities, steel mills, and related industries, while also providing a cost-effective source of raw materials for steel production.",
      " Recycled iron derived from scrap is used in various industries, including construction, automotive, manufacturing, and infrastructure development, for producing new steel products such as beams, pipes, rebars, and automotive parts. It is also used in the production of consumer goods, machinery, and appliances.",
    ],
  },
  {
    id: 4,
    image: "d4.png",
    name: "Brass Scrap",
    paragraphs: [
      "Brass scrap undergoes a recycling process similar to other metal scraps. It involves collection, sorting, cleaning, and melting the scrap in furnaces to separate and recover the brass content.",
      "Brass scrap holds significant value in the recycling market due to its high copper content and the demand for brass alloys in manufacturing various products, including plumbing fixtures, musical instruments, hardware, and decorative items.",
      "Recycled brass derived from scrap is used in a wide range of applications across industries, including construction, plumbing, electrical engineering, automotive manufacturing, marine equipment, and artistic craftsmanship..",
      "Brass scrap can include various forms such as brass plumbing fixtures, valves, faucets, fittings, electrical components, decorative items, and industrial machinery parts.",
    ],
  },
  {
    id: 5,
    image: "d5.png",
    name: "Dollar Chana",
    paragraphs: [
      " Dollar chana tends to have a firm texture and a nutty flavor profile. When cooked, they retain their shape well and have a satisfying bite.",
      " Dollar chana is widely used in Indian cooking, particularly in dishes like chana masala, chole, and various curries. They can also be sprouted and used in salads or as a garnish for snacks.",
      " Like other types of chickpeas, dollar chana is a rich source of plant-based protein, dietary fiber, vitamins, and minerals. They are particularly high in protein and carbohydrates, making them a nutritious addition to vegetarian and vegan diets.",
      " Dollar chana is available in grocery stores and markets, both in its dried form and sometimes in canned or precooked forms for convenience. It is also commonly found in bulk bins in health food stores or specialty markets catering to Indian cuisine.",
    ],
  },
  {
    id: 6,
    image: "d6.png",
    name: "Garlic",
    paragraphs: [
      " Garlic is a staple ingredient in cuisines worldwide, prized for its pungent flavor and aroma. It can be used fresh, minced, crushed, sliced, or roasted to enhance the taste of savory dishes such as soups, stews, sauces, marinades, stir-fries, and salads.",
      " Garlic is not only valued for its culinary properties but also for its potential health benefits. It is a rich source of vitamins and minerals, including vitamin C, vitamin B6, manganese, and selenium. Garlic also contains sulfur compounds like allicin, which are believed to have antioxidant and antimicrobial properties",
      " Garlic has been used for centuries in traditional medicine for its purported health benefits, which include boosting the immune system, reducing blood pressure and cholesterol levels, improving cardiovascular health, and possessing anti-inflammatory and antibacterial properties.",
    ],
  },
  {
    id: 7,
    image: "d7.png",
    name: "Ginger",
    paragraphs: [
      " It adds a pungent flavor to dishes worldwide, from savory meals to desserts and beverages, enhancing the taste and aroma of various cuisines.",
      " Rich in antioxidants and vitamins like vitamin C, ginger aids digestion and may reduce inflammation, contributing to overall health and well-being.",
      " It alleviates nausea, aids digestion, relieves muscle pain, and supports immune function, making it a versatile remedy in traditional and modern medicine. ",
      " Ginger holds cultural significance globally and symbolizes prosperity, health, and hospitality in many traditions, reflecting its deep-rooted importance in human history and culture.",
    ],
  },
  {
    id: 8,
    image: "d8.png",
    name: "Onion",
    paragraphs: [
      " Onions are a fundamental ingredient in various cuisines, providing a pungent flavor and aroma. They can be used raw in salads, sliced in sandwiches, or cooked in a variety of dishes such as soups, stews, and stir-fries.",
      " Onions are a good source of vitamins, particularly vitamin C and vitamin B6. They also contain dietary fiber, antioxidants, and trace minerals. Consuming onions may contribute to overall health and immune support.",
      " Onions have potential health benefits, including anti-inflammatory and antimicrobial properties. They may also help lower the risk of certain chronic diseases, such as cardiovascular issues, when included as part of a balanced diet. ",
      " Onions have cultural and historical significance worldwide. They have been used in traditional medicine for their potential health benefits and have symbolic importance in various cultures, representing endurance, strength, and unity.",
    ],
  },
  {
    id: 9,
    image: "d9.png",
    name: "Chana Daal",
    paragraphs: [
      " Chana dal is highly nutritious, rich in protein, dietary fiber, vitamins, and minerals. It provides essential nutrients such as iron, folate, manganese, and magnesium, making it a healthy addition to vegetarian and vegan diets.",
      " Chana dal is versatile and can be used in a variety of dishes, including curries, soups, stews, salads, and snacks. It has a nutty flavor and a firm texture when cooked, adding depth and richness to recipes.",
      " Consuming chana dal may offer several health benefits, including improved digestion, enhanced satiety, and better blood sugar control. Its high fiber content helps promote digestive health and may reduce the risk of chronic diseases like heart disease and diabetes.",
      " Chana dal holds cultural significance in Indian cuisine and is often prepared during festivals, celebrations, and religious ceremonies. It is a symbol of prosperity and is considered auspicious in many Indian households.",
    ],
  },
  {
    id: 10,
    image: "d10.png",
    name: "Groundnut",
    paragraphs: [
      "Groundnut seeds are nutrient-dense and provide a rich source of plant-based protein, healthy fats, vitamins, minerals, and dietary fiber. They contain essential nutrients like vitamin E, niacin, folate, magnesium, and phosphorus.",
      "Groundnut seeds are consumed in various forms, including raw, roasted, boiled, or processed into peanut butter, oil, flour, and other products. They are used in savory dishes, salads, snacks, desserts, and as a key ingredient in many cuisines worldwide.",
      "Groundnut seeds offer numerous health benefits, including supporting heart health, reducing the risk of chronic diseases like diabetes and cancer, promoting weight management, and providing sustained energy due to their nutrient-rich composition.",
    ],
  },
  {
    id: 11,
    image: "d11.png",
    name: "Moong Daal",
    paragraphs: [
      "Moong dal is highly nutritious, rich in plant-based protein, dietary fiber, vitamins, and minerals. It is a good source of folate, iron, magnesium, and potassium, making it a healthy addition to vegetarian and vegan diets.",
      "Moong dal is versatile and can be used in various dishes such as soups, stews, curries, salads, and desserts. It cooks relatively quickly compared to other lentils and has a creamy texture when cooked, making it ideal for both savory and sweet recipes.",
      "Consuming moong dal may offer several health benefits, including improved digestion, blood sugar regulation, and heart health. Its high fiber content helps promote digestive health and may aid in weight management.",
    ],
  },
  {
    id: 12,
    image: "d12.png",
    name: "Urad Daal",
    paragraphs: [
      "Urad dal is a versatile ingredient in Indian cuisine, commonly used to make dishes such as dal makhani, dosa, idli, vada, and papad. It has a rich and creamy texture when cooked, making it suitable for soups, stews, and purees.",
      "Urad dal is highly nutritious, rich in protein, dietary fiber, vitamins, and minerals. It is a good source of iron, potassium, calcium, magnesium, and B vitamins, which are essential for overall health and well-being. ",
      "Consuming urad dal may offer several health benefits, including improved digestion, heart health, and blood sugar regulation. Its high fiber content helps promote digestive regularity and may aid in weight management.",
      " Urad dal holds cultural significance in Indian cuisine and is often included in traditional recipes passed down through generations. It is considered a staple food in many Indian households and is commonly used in festive and celebratory dishes.",
    ],
  },
  {
    id: 13,
    image: "d13.png",
    name: "Rice",
    paragraphs: [
      "There are thousands of rice varieties, each with its own texture, flavor, and culinary uses. Some common types include long-grain rice, short-grain rice, basmati rice, jasmine rice, and Arborio rice.",
      "Rice is a good source of carbohydrates, providing energy to the body. Depending on the variety, it may also contain varying amounts of protein, fiber, vitamins, and minerals.",
      "Rice is a versatile ingredient used in a wide range of dishes worldwide, including main courses, side dishes, desserts, and beverages. It serves as a base for dishes like biryani, paella, risotto, sushi, and rice pudding.",
      "Rice is primarily grown in flooded rice paddies or fields, although some varieties can also thrive in dry conditions. It requires specific environmental conditions, including warm temperatures, ample water, and fertile soil.",
    ],
  },
  {
    id: 14,
    image: "d14.png",
    name: "Red Chilli",
    paragraphs: [
      "Red chili powder adds a spicy and pungent flavor to dishes along with varying degrees of heat. The level of spiciness can vary based on the type of chili pepper used and whether the seeds and membranes are included during the grinding process.",
      "Red chili powder is a versatile spice used in a wide range of cuisines around the world, including Indian, Mexican, Thai, and Middle Eastern cuisine. It is used to season and add heat to curries, stews, soups, marinades, sauces, and spice blends.",
      "Red chili powder is rich in vitamins, particularly vitamin C, and also contains minerals like potassium, manganese, and iron. It may also have health benefits due to its antioxidant properties and potential to boost metabolism.",
    ],
  },
  {
    id: 15,
    image: "d15.png",
    name: "Wheat Flour",
    paragraphs: [
      "There are several varieties of wheat flour, including whole wheat flour, white wheat flour, bread flour, all-purpose flour, pastry flour, and cake flour. Each variety has different protein levels and gluten content, which affects its texture and baking properties.",
      " Wheat flour is a rich source of carbohydrates, fiber, protein, vitamins, and minerals. It contains essential nutrients such as B vitamins, including folate and niacin, as well as minerals like iron, magnesium, and zinc.",
      "Wheat flour is a versatile ingredient used in a wide range of culinary applications, including baking bread, cakes, cookies, pastries, muffins, pancakes, and noodles. It serves as a key ingredient in many staple foods and baked goods around the world.",
    ],
  },
  {
    id: 16,
    image: "d16.png",
    name: "Soyabean",
    paragraphs: [
      "Soybeans are highly nutritious and contain all nine essential amino acids, making them a complete protein source for vegetarians and vegans. They are also rich in fiber, vitamins (such as folate and vitamin K), minerals (including iron, calcium, and magnesium), and phytonutrients.",
      " Soybeans are used in various culinary applications. They can be consumed whole (edamame), processed into soy milk, tofu, tempeh, and soy sauce, and used as ingredients in a wide range of dishes, including stir-fries, salads, soups, and desserts.",
      "    Consuming soybeans and soy products has been associated with various health benefits, including improved heart health, reduced risk of certain cancers (such as breast and prostate cancer), better bone health, and management of menopausal symptoms.",
    ],
  },
  {
    id: 17,
    image: "d17.png",
    name: "Groundnut Oil",
    paragraphs: [
      "Groundnut seeds are nutrient-dense and provide a rich source of plant-based protein, healthy fats, vitamins, minerals, and dietary fiber. They contain essential nutrients like vitamin E, niacin, folate, magnesium, and phosphorus.",
      "Groundnut seeds are consumed in various forms, including raw, roasted, boiled, or processed into peanut butter, oil, flour, and other products. They are used in savory dishes, salads, snacks, desserts, and as a key ingredient in many cuisines worldwide.",
      "Groundnut seeds offer numerous health benefits, including supporting heart health, reducing the risk of chronic diseases like diabetes and cancer, promoting weight management, and providing sustained energy due to their nutrient-rich composition.",
    ],
  },
  {
    id: 18,
    image: "d18.png",
    name: "Potatoes",
    paragraphs: [
      " Potatoes are one of the most versatile and widely consumed vegetables globally. They can be boiled, steamed, roasted, mashed, fried, or baked, and are used in a vast array of dishes, including soups, salads, stews, casseroles, and side dishes.",
      " Potatoes are a good source of carbohydrates, fiber, vitamins, and minerals. They provide essential nutrients such as vitamin C, potassium, vitamin B6, and folate. However, their nutritional content can vary depending on the variety and preparation method.",
      "Potatoes hold cultural significance in many societies and cuisines around the world. They have been a dietary staple in many cultures for centuries and have played a crucial role in alleviating hunger and food insecurity in times of scarcity.",
    ],
  },
];

export default productsData;
