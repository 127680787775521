import React, { Component } from "react";
import TopButton from "../../components/topButton/TopButton.js";
import "./contact.css";
import { Fade } from "react-reveal";
import SocialMedia from "../../components/socialMedia/SocialMedia.js";
const words = "Contact Us".split(" ");
class Experience extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="experience-main" id="contact">
        <div className="basic-experience">
          <Fade bottom duration={2000} distance="40px">
            <div className="experience-heading-div">
              <div className="experience-heading-img-div">
                <img
                  alt="Logo"
                  src={require("../../assests/images/contacts.png")}
                ></img>
              </div>
              <div className="experience-heading-text-div">
                <h3>Call us - +91-7974194260</h3>
                <h3>Location- 604 B Block Anand Valley, Chitawad,</h3>
                <h3>Indore, MP, India - 452001</h3>
                <SocialMedia theme={theme} />
              </div>
            </div>
          </Fade>
        </div>
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Experience;
