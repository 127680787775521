import React, { Component } from "react";
import Header from "../../components/header/Header";
import Greeting from "../../containers/greeting/Greeting";
import Products from "../../containers/products/Products";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import Contact from "../contact/contact";
import About from "../about/about";
import Certificate from "../certificates/certificates";
class Home extends Component {
  render() {
    return (
      <div>
        <Header theme={this.props.theme} />
        <Greeting theme={this.props.theme} />
        <Products theme={this.props.theme} />
        <About theme={this.props.theme} />
        <Certificate theme={this.props.theme} />
        <Contact theme={this.props.theme} />
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Home;
