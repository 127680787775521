const products = {
  projects: [
    {
      title: "Aluminium Scrap",
      subtitle: "(Import)",
      detail: "aluminium scrap",
      logo_path: "aluminium.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Copper Pure Wire Scrap",
      subtitle: "(Import)",
      detail: "copper wire",
      logo_path: "cu.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Iron Scrap",
      subtitle: "(Import)",
      detail: "iron scrap",
      logo_path: "iron.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Brass Scrap",
      subtitle: "(Import)",
      detail: "brass scrap",
      logo_path: "brass.png",
      color_code: "#CCCCCC66",
    },
    {
      title: " Dollar Chana ( Chickpeas)",
      subtitle: "(Export)",
      detail: "Dollar chana",
      logo_path: "chana.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Garlic",
      subtitle: "(Export)",
      detail: "garlic",
      logo_path: "garlic.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Ginger",
      subtitle: "(Export)",
      detail: "ginger",
      logo_path: "ginger.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Onion",
      subtitle: "(Export)",
      detail: "onion",
      logo_path: "onion.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Chana Daal",
      subtitle: "(Export)",
      detail: "Chana daal",
      logo_path: "chanadaal.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Groundnut",
      subtitle: "(Export)",
      detail: "groundnut",
      logo_path: "ground.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Moong Daal",
      subtitle: "(Export)",
      detail: "moong daal",
      logo_path: "moong.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Urad Daal",
      subtitle: "(Export)",
      detail: "urad daal",
      logo_path: "urad.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Rice",
      subtitle: "(Export)",
      detail: "rice",
      logo_path: "rice.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Red Chilli ",
      subtitle: "(Export)",
      detail: "red chilli",
      logo_path: "red.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Wheat Flour ( Gehu ka Aata)",
      subtitle: "(Export)",
      detail: "wheat flour",
      logo_path: "aata.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Soyabean",
      subtitle: "(Export)",
      detail: "soyabean",
      logo_path: "soya.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Groundnut Oil",
      subtitle: "(Export)",
      detail: "groundnut oil",
      logo_path: "groundnut.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Potatoes",
      subtitle: "(Export)",
      detail: "Potatoes",
      logo_path: "patatoes.png",
      color_code: "#CCCCCC66",
    },
  ],
};

export { products };
