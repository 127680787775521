import React from "react";
import "./greet.css";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import LoopingVideo from "../../components/greetVideo/video";
export default function Greeting(props) {
  const theme = props.theme;
  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1
                className="greeting-text"
                style={{ color: "#a7392b", fontSize: "40px" }}
              >
                {greeting.title}
              </h1>
              {/* <TypingAnimation /> */}
              <h2 className="greeting-nickname" style={{ color: theme.text }}>
                Bridging Nations, Cultivating Quality
              </h2>
              <p
                className="greeting-text-p subTitle"
                style={{ color: "rgb(82 84 115)", fontSize: "19px" }}
              >
                {greeting.subTitle}
              </p>
            </div>
          </div>
          <div className="greeting-image-div">
            <LoopingVideo />
          </div>
        </div>
      </div>
    </Fade>
  );
}
